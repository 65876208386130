import React, { Component } from "react"
import "./footer.css"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import ReCaptcha from "react-google-recaptcha"

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Name must be 2 characters at minimum")
    .required("Name is required"),
  email: Yup.string()
    .email("Invalid email address format")
    .required("Email is required"),
  message: Yup.string()
    .min(5, "Message must be 5 characters at minimum")
    .required("Message is required"),
})

class ContactNew extends Component {
  constructor(props, ...args) {
    super(props, ...args)
    this.state = {
      ReCaptcha: null,
      callback: "not fired",
      value: null,
      load: false,
      expired: "false",
    }
    this._reCaptchaRef = React.createRef()
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ load: true })
    })
  }

  handleChange = value => {
    this.setState({ value })
    this.setState({ ReCaptcha: value })
    // if value is null recaptcha expired
    if (value === null) this.setState({ expired: "true" })
  }

  asyncScriptOnLoad = () => {
    this.setState({ callback: "called!" })
  }

  render() {

    return (
      <section id="contact">
        <div className="inner">
          <section>
            <Formik
              initialValues={{
                name: "",
                email: "",
                message: "",
                ReCaptcha: null,
              }}
              validationSchema={validationSchema}
              onSubmit={({ setSubmitting }) => {
                alert("Form is validated! Submitting the form...")
                setSubmitting(false)
              }}
            >
              {({ touched, errors, isSubmitting }) => (
                <Form
                  name="contact"
                  method="post"
                  action=""
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                >
                  <input type="hidden" name="bot-field" />
                  <input type="hidden" name="form-name" value="contact" />
                  <div className="field half first">
                    <label htmlFor="name">Name</label>
                    <Field
                      type="name"
                      name="name"
                      placeholder="Enter name"
                      className={`form-control ${
                        touched.name && errors.name ? "is-invalid" : ""
                      }`}
                    />
                    <ErrorMessage
                      component="div"
                      name="name"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="field half">
                    <label htmlFor="email">Email</label>
                    <Field
                      type="email"
                      name="email"
                      placeholder="Enter email"
                      className={`form-control ${
                        touched.email && errors.email ? "is-invalid" : ""
                      }`}
                    />
                    <ErrorMessage
                      component="div"
                      name="email"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="field">
                    <label htmlFor="message">Message</label>
                    <Field
                      type="textarea"
                      component="textarea"
                      name="message"
                      placeholder="Enter message"
                      className={`form-control ${
                        touched.message && errors.message ? "is-invalid" : ""
                      }`}
                    />
                    <ErrorMessage
                      component="div"
                      name="message"
                      className="invalid-feedback"
                    />
                  </div>
                  <div style={{ paddingTop: "1rem" }}>
                    <ReCaptcha
                      style={{ display: "inline-block" }}
                      ref={this._reCaptchaRef}
                      value={this.state.ReCaptcha}
                      sitekey="6LeiT-cUAAAAAIByctvwnrAmvBqHyRArV5minzvJ"
                      onChange={this.handleChange}
                      asyncScriptOnLoad={this.asyncScriptOnLoad}
                    />
                    <ErrorMessage
                      component="div"
                      name="ReCaptcha"
                      className="invalid-feedback"
                    />
                    <button
                      type="submit"
                      className="btn btn-primary btn-block"
                      disabled={
                        isSubmitting || this.state.ReCaptcha === null
                          ? true
                          : false
                      }
                    >
                      {isSubmitting ? "Please wait..." : "Submit"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </section>
        </div>
      </section>
    )
  }
}

export default ContactNew
